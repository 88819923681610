$primary: #00213b;
$secondary: #C09A18;
$lightGrey: #f5f6f7;
$secondaryLowAlpha: #e5d6a2;

$small-font: 18px;
$medium-font: 24px;
$large-font: 32px;
$small-line-height: 24px;
$medium-line-height: 38px;
$large-line-height: 45px;

$small-font-mob: 18px;
$medium-font-mob: 22px;
$large-font-mob: 28px;
$small-line-height-mob: 24px;
$medium-line-height-mob: 30px;
$large-line-height-mob: 32px;