.login-content {
  width: 100%;
  height: 100vh;
  .login-header {
    padding: 10px 20px;
    img {
      width: 200px;
    }
  }
  .login-content {
    width: 320px;
    margin: 50px auto;
    height: calc(100% - 200px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 480px) {
      width: 100%;
     }
    .wellcome-msg {
      width: 100%;
      font-size: $medium-font;
      letter-spacing: 1px;
    }
    .login-form {
      width: 100%;
      .input-holder {
        position: relative;
        width: 100%;
        margin-bottom: 10px;
        height: 46px;
        input {
          background: white;
          color: $primary;
          border: 1px solid #333333;
          padding: 10px 15px;
          width: 100%;
          height: 100%;
          margin-bottom: 20px;
          font-size: $small-font;
          &.error {
            border: 1px solid #ff4d4d;
          }
          &:focus {
            border: 1px solid $secondary;
            outline: none;
          }
          &::placeholder {
            color: #333333;
          }
        }
        .input-error {
          position: absolute;
          top: 5px;
          right: 10px;
          z-index: 1;
          p {
            font-size: 12px;
            font-weight: bold;
            color: #ff4d4d;
            margin: 0;
          }
        }
      }
      .btn-style {
        padding: 10px 20px;
        min-width: 130px;
        min-height: 45px;
        font-size: $small-font;
        margin-left: 0;
        &.loading {
          justify-content: center;
          img {
            width: 20px;
          }
        }
      }
    }
  }
}