.popup-view {
  position: fixed;
  top: 40px;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  z-index: 101;
  .popup-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: scroll;
    .popup-container {
      width: 300px;
      height: fit-content;
      margin: 50px auto;
      padding: 20px;
      background-color: white;
      border-radius: 3px;
      box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, .25);
      position: relative;
      z-index: 102;
      text-align: center;
      &.edit-user {
        width: 50%;
        @media (max-width: 767px) {
          width: 90%
        }
        .fragment-row {
          input, select {
            width: 47%;
            margin-right: 5px;
          }
        }
       
      }
      p {
        color: #333333;
        text-align: center;
        padding: 0 10px;
        font-weight: bold;
        letter-spacing: 1px;
        vertical-align: middle;
        margin-top: 30px;
      }
    }
  }
}