.home-panel {
  padding: 30px 0;
  .row {
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
  .chart-wrap {
    margin-top: 50px;
    border: 1px solid gray;
    border-radius: 3px;
    padding: 25px;
    width: 47%;
    @media (max-width: 767px) {
      width: 90%;
      margin-top: 0;
      margin-bottom: 20px;
    }
  }
  .bar-wrap {
    margin-top: 50px;
    border: 1px solid gray;
    border-radius: 3px;
    padding: 25px;
    width: 47%;
    @media (max-width: 767px) {
      width: 90%;
      margin-top: 0;
    }
  }
}