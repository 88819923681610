footer {
  border-top: 1px solid #684242;
  .footer-content {
    padding: 10px 0;
    padding-left: 70px;
    display: flex;
    align-items: center;
    span {
        margin: 3px;
        &:nth-child(2) {
          @media (max-width: 767px) {
            display: none;
          }
        }
    }
    @media (max-width: 767px) {
      flex-direction: column;
      padding-left: 0;
    }
  }
}