.data-box {
  border: 1px solid gray;
  width: 30%;
  display: flex;
  align-items: center;
  padding: 25px;
  border-radius: 3px;
  color: gray;
  @media (max-width: 767px) {
    width: 90%;
    margin-bottom: 20px;
  }
  .box-details {
    flex: 1;
    display: flex;
    flex-direction: column;
    .title {
        font-size: 22px;
    }
  }
  svg {
    font-size: 35px;
  }
}