.users-panel {
  padding: 30px 0;
  .add-client-content {
    width: 100%;
    margin: 0 auto 10px;
    display: flex;
    align-items: flex-end;
    p {
      flex: 1;
      margin: 0;
    }
    button {
      padding: 10px 20px;
    }
  }
  .table-wrapper {
    width: 100%;
    margin: auto;
    border: 1px solid #D4D4D4;
    border-radius: 2px;
    background-color: white;
    padding-bottom: 5px;
    table {
      width: 100%;
      border-collapse: collapse;
      tbody {
        tr {
          min-height: 50px;
          &.deleted {
            opacity: 0.3;
          }
          &:nth-child(odd) {
            background-color:  $lightGrey;
          }
          td { 
            border: none;
            &:first-child {
              width: 85%;
            }
            .table-cell {
              min-height: 50px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              padding-left: 10px;
              font-size: 18px;
              letter-spacing: 1px;
              &.with-btns {
                flex-direction: row;
                align-items: center;
                @media (max-width: 767px) {
                  flex-direction: column;
                  align-items: flex-start;
                  button {
                    margin-bottom: 5px;
                  }
                }
              }
              p {
                margin: 0;
              }
              span {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
  .form-fragment {
    margin-bottom: 40px;
    @media (max-width: 480px) {
      font-size: 14px;
    }
    .title-wrapper {
      display: flex;
      align-items: center;
      border-bottom: 1px solid gray;
      margin-bottom: 10px;
      .fragment-title {
        font-weight: normal;
        font-size: 20px;
        margin: 0 0 5px 0;
        letter-spacing: 2px;
        color: #333333;
        flex: 1;
        margin-right: 10px;
        padding-left: 5px;
        @media (max-width: 480px) {
          font-size: 16px;
        }
      }
      &.with-segments {
        justify-content: space-between;
        margin-bottom: 0;
        .fragment-title {
          min-width: 100px;
          &:nth-child(2), &:nth-child(3), &:nth-child(4) {
            @media (max-width: 767px) {
              display: none;
            }
          }
        }
      }
    }
    .fragment-row {
      display: flex;
      margin-bottom: 20px;
      @media(max-width: 991px) {
        margin-bottom: 10px;
        justify-content: space-between;
      }
      input {
        height: 40px;
        width: 300px;
        margin-right: 50px;
        padding-left: 10px;
        &:focus {
          border: 1px solid $secondary;
          outline: none;
        }
        @media(max-width: 991px) {
          width: 47%;
          height: 35px;
          margin-right: 0;
        }
      }
      select {
        height: 40px;
        width: 300px;
        padding-left: 10px;
        &:focus {
          border: 1px solid $secondary;
          outline: none;
        }
        @media(max-width: 991px) {
          height: 35px;
          width: 47%;
        }
      }
      &.with-segments {
        justify-content: space-between;
        margin-bottom: 5px;
        &:nth-child(odd) {
          background-color: #f5f6f7;
        }
        &.deleted {
          opacity: 0.3;
        }
        .data-cell {
          font-size: 15px;
          padding-left: 5px;
          min-width: 100px;
          flex: 1;
          display: flex;
          align-items: center;
          // word-break: break-all;
          overflow-x: hidden;
          margin-right: 10px;
          @media (max-width: 767px) {
            min-width: unset;
          }
          &:nth-child(2), &:nth-child(3), &:nth-child(4) {
            @media (max-width: 767px) {
              display: none;
            }
          }
          &:last-child {
            justify-content: flex-end;
            button {
              margin-right: 0;
            }
          }
        }
      }
      &.center {
        justify-content: center;
        font-style: italic;
        letter-spacing: 1px;
      }
    }
    .checkbox-row {
      margin-bottom: 15px;
      label {
        width: 150px;
      }
    }
  }
  .row-align-right {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    label {
      margin-right: 50px;
      @media (max-width: 480px) {
        margin-right: 5px;
      }
    }
    button {
      margin-right: 0;
    }
  }
}