.sidebar_container {
  position: fixed;
  top: 70px;
  left: 0;
  bottom: 0;
  overflow-y: scroll;
  z-index: 150;
  width: 70px;
  background-color: $lightGrey;
  transform: translateX(-102%);
  transition: transform 0.3s ease-in-out;
  &.open {
    transform: translateX(0);
  }
  .sidebar_list {
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    .sidebar_item {
      width: 100%;
      height: 3em;
      text-align: center;
      margin-bottom: 15px;
      &.selected_tab {
        background-color: $secondary;
      } 
      svg {
        font-size: 25px;
        height: 100%;
        color: gray;
      }
    }
  }
}


::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $primary; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary;; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary;; 
}



