* {
  box-sizing: border-box;
}

@font-face {
  font-family: frutiger;
  src: url("./../assets/fonts/FrutigerLTPro-Light.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: frutigerBold;
  src: url("./../assets/fonts/FrutigerLTPro-Light.otf");
  font-weight: bold;
  font-style: normal;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: "frutiger", "sans-serif";
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  &:hover {
    transform: scale(1.02);
  }
  &:focus {
    outline: none;
  }
  &.btn-style {
    border: none;
    background-color: gray;
    padding: 5px 15px;
    color: white;
    font-size: 13px;
    letter-spacing: 0.7px;
    border-radius: 2px;
    margin: 0 5px;
    svg {
      font-size: 18px;
    }
    &:disabled {
      opacity: 0.6;
      cursor: unset;
      &:hover {
        transform: scale(1);
      }
    }
  }
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-content {
  width: 100%;
  min-height: calc(100vh - 115px);
  margin-top: 70px;
  position: relative;
  padding-left: 70px;
  @media (max-width: 767px) {
    padding-left: 0;
    min-height: calc(100vh - 140px);;
  }
}

.checkbox {
  display: inline-flex;
  align-content: center;
  cursor: pointer;
  position: relative;
  span {
    color: #333333;
    padding: 0.1rem 0;
  }
  input {
    height: 15px;
    width: 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 1px solid $primary;
    border-radius: 2px;
    outline: none;
    transition-duration: 0.3s;
    cursor: pointer;
    &:focus {
      border: 1px solid #333333 !important;
      outline: none;
    }
    &:disabled {
      background-color: lightgray;
    }
  }
}
.checkbox > input:checked + span::before {
  content: '\2713';
  display: block;
  text-align: center;
  color: $secondary;
  position: absolute;
  left: 0.3rem;
  top: -0.07em;
  font-weight: bold;
}

.wrn-massage {
  min-height: 20px;
  color: red;
  font-size: 14px;
  display: inline-block;
  margin-bottom: 5px;
}
