.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  z-index: 200;
  background-color: white;
  border-bottom: 1px solid rgb(104, 66, 66);
  .container {
    height: 100%;
  }
  .header_content {
    display: flex;
    padding: 0;
    height: 100%;
    .header-right {
      flex: 1;
      display: flex;
      height: 100%;
      justify-content: flex-end;
      .logout-btn {
        background-color: transparent;
        border: none;
        font-size: 25px;
        color: gray;
      }
      // img {
      //   width: 200px;
      //   height: auto;
      //   @media (max-width: 767px) {
      //     width: 170px;
      //   }
      // }
    }
    .header-left {
      background-color: gray;
      width: 70px;
      height: 71px;
      position: absolute;
      left: 0;
      bottom: -1px;
      display: flex;
      justify-content: center;
      align-items: center;
      .button_toggle {
        width: 40px;
        height: 40px;
        background-color: transparent;
        border: none;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        cursor: pointer;
        padding: 0;
        &:focus {
          outline: none;
        }
        &:active {
          outline: none;
          transform:  scale(0.9, 0.9);
        }
        .button_toggle_line {
          width: 30px;
          height: 3px;
          background-color: white;
        }
      }
    }
 
    // .spacer {
    //   flex: 1;
    // }
  }
}